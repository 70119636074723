import { useState } from "react";

const Main = () => {
    const [showLogoutConfirm, setShowLogoutConfirm] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedOption, setSelectedOption] = useState("");
    const [accordionOpen, setAccordionOpen] = useState({});


    const options = [
        {
            label: 'Company Profile',
            links: [
                { label: 'Application Service', url: `${process.env.REACT_APP_BASE_URL}/api/docs/compro/app` },
                { label: 'Token Service', url: `${process.env.REACT_APP_BASE_URL}/api/docs/compro/token` },
                { label: 'CMS Service', url: `${process.env.REACT_APP_BASE_URL}/api/docs/compro/cms` }
            ]
        },
        {
            label: 'Benings Ecommerce',
            links: [
                { label: 'Application Service', url: `${process.env.REACT_APP_BASE_URL}/api/docs/beningsEcommerce/app` },
                { label: 'Logistic Service', url: `${process.env.REACT_APP_BASE_URL}/api/docs/beningsEcommerce/logistic` },
                { label: 'Notification Service', url: `${process.env.REACT_APP_BASE_URL}/api/docs/beningsEcommerce/notification` },
                { label: 'Report Service', url: `${process.env.REACT_APP_BASE_URL}/api/docs/beningsEcommerce/report` }
            ]
        },
        {
            label: 'Benings Antrian',
            links: [
                { label: 'Application Service', url: `${process.env.REACT_APP_BASE_URL}/api/docs/beningsAntrian/app` },
                { label: 'CMS Service', url: `${process.env.REACT_APP_BASE_URL}/api/docs/beningsAntrian/cms` },
            ]
        }
    ];


    const handleChange = (optionLabel) => {
        setSelectedOption(optionLabel);
        setAccordionOpen((prevAccordionOpen) => ({
            ...prevAccordionOpen,
            [optionLabel]: !prevAccordionOpen[optionLabel] // Mengubah status accordion untuk opsi yang diklik
        }));
    };

    const confirmLogout = () => {
        setIsLoading(true);
        setTimeout(() => {
            // Hapus semua cookie
            document.cookie.split(";").forEach((c) => {
                document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
            });
            // Arahkan kembali ke halaman login
            window.location.href = './auth';    
        },1000)
    }

    const logoutHandler = () => {
        setShowLogoutConfirm(true);
    };



    return (
        <div className="doodle-bg bg-gray-100 text-gray-900 min-h-screen flex items-center justify-center">
            <style jsx>{`
                .btn.bg-maroon {
                    background-color: #800000; /* Maroon */
                }
                .btn.bg-maroon:hover {
                    background-color: #520000; /* Darker Maroon */
                }
                .btn {
                    transition: transform 0.2s, background-color 0.3s;
                    background-color: #4A4A4A;
                    color: #fff;
                    padding: 0.75rem 1rem;
                    font-size: 1rem;
                    border-radius: 0.5rem;
                    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
                    margin: 0.2rem 0;
                    text-align: center;
                    border: 1px solid rgba(0, 0, 0, 0.1);
                    width: 100%;
                    box-sizing: border-box;
                    cursor: pointer;
                }
                .btn:hover {
                    transform: scale(1.05);
                    background-color: #2C2C2C;
                }
                .accordion{
                    padding: 0;
                    margin: 0;
                    justify-content: center;
                    align-items: center;
                    align-content: center;
                }
                .accordion-icon {
                    transition: transform 0.2s;
                    display: inline-block;
                    transform: rotate(0deg);
                    float: inline-end
                }
                .panel{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-content: center;
                    align-items: center;
                    width: 100%;
                    max-width: 600px;
                    box-sizing: border-box;
                }
                .panel a{
                    transition: transform 0.2s, background-color 0.3s;
                    background-color: #4A4A;
                    color: #fff;
                    padding: 0.75rem 2rem;
                    font-size: 1rem;
                    border-radius: 0.5rem;
                    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
                    margin: 0.2rem 0;
                    text-align: center;
                    border: 1px solid rgba(0, 0, 0, 0.1);
                    width: 90%;
                    box-sizing: border-box;
                    cursor: pointer;
                    text-decoration: none;
                }
                .panel a:hover {
                    transform: scale(1.05);
                    background-color: #047704e6;
                }
                .doodle-bg {
                    margin: 0;
                    padding: 0;
                    background-image: url('https://images5.alphacoders.com/390/390081.jpg');
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                    height: 100vh;
                    width: 100vw;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .form-container {
                    background: rgba(255, 255, 255, 0.9);
                    backdrop-filter: blur(10px);
                    padding: 2rem;
                    border-radius: 1rem;
                    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 100%;
                    max-width: 600px;
                    box-sizing: border-box;
                }
                .button-column {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                }
                .modal-custom {
                    background: rgba(255, 255, 255, 0.9);
                    backdrop-filter: blur(10px);
                    padding: 2rem;
                    border-radius: 1rem;
                    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
                    max-width: 600px;
                    position: fixed;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    z-index: 1000;
                }
                .modal-title {
                    display: flex;
                    justify-content: center;
                    font-size: 1.5rem;
                    font-weight: normal;
                }
                .loading-overlay {
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: rgba(255, 255, 255, 0.8);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    z-index: 1100;
                    
                }
                .spinner-border {
                    width: 3rem;
                    height: 3rem;
                    border-width: 0.3rem;
                }

                .loading-image {
                    border: 16px solid #f3f3f3; 
                    border-top: 16px solid maroon;
                    border-radius: 50%;
                    width: 80px;
                    height: 80px;
                    animation: spin 1s linear infinite;
                }
                @keyframes spin {
                    0% { transform: rotate(0deg); }
                    100% { transform: rotate(360deg); }
                }
            `}</style>
            <div className="form-container">
                <h1 className="text-4xl font-bold mb-6 text-center text-gray-800">API Documentation</h1>
                <p className="text-center text-gray-600 mb-8">Explore our comprehensive API documentation to integrate and enhance your applications seamlessly.</p>
                <div className="button-column">
                    {options.map((option, index) => (
                        <div className="accordion" key={index}>
                            <div>
                                <button 
                                    onClick={() => handleChange(option.label)} 
                                    className={`btn bg-blue-600 text-white px-4 shadow-lg hover:bg-blue-700 transition duration-300 ${selectedOption === option.label ? "active" : ""}`}
                                >
                                    {option.label}
                                    <div className="accordion-icon">
                                        {accordionOpen[option.label] ? <span>&#x25BC;</span> : <span>&#x25B6;</span>}
                                    </div>
                                </button>
                                {accordionOpen[option.label] && (
                                    <div className="panel">
                                        {option.links.map((link, index) => (
                                            <a key={index} href={link.url} target="_blank" rel="noreferrer" className="bg-blue-600 text-white py-2 px-4 shadow-lg hover:bg-blue-700 transition duration-300">{link.label}</a>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                    <button onClick={logoutHandler} className="btn bg-maroon text-white py-2 px-4 rounded-lg shadow-lg hover:bg-dark-maroon transition duration-300 mt-4">Logout</button>
                </div>
                     {showLogoutConfirm && (
                    <>
                        <div className="modal-custom">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Confirm Logout</h5>
                                </div>
                                <div className="modal-body">
                                    <p>Are you sure you want to logout?</p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-primary" onClick={confirmLogout}>Yes, Logout</button>
                                    <button type="button" className="btn btn-secondary" onClick={() => setShowLogoutConfirm(false)}>Cancel</button>
                                </div>
                            </div>
                    </div>
                    </>
                    
                )}
                {isLoading && (
                    <div className="loading-overlay">
                        <div alt="Loading" className="loading-image" ></div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Main;